import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyNumber]',
  standalone: true,
})
export class OnlyNumberDirective {
  @Input() inputType: any;
  constructor(private elementRef: ElementRef) {}

  @HostListener('input', ['$event'])
  onlyNumberAllow(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    let newValue: any;

    // Check if inputType is provided and it's decimal
    if (this.inputType === 'decimal') {
      newValue = input.value.replace(/[^\d.]/g, '');

      // Ensure only one decimal point and it's not at the start or end
      const decimalCount = newValue.split('.').length - 1;
      if (decimalCount > 1 || newValue.startsWith('.')) {
        newValue = newValue.slice(0, -1); // Remove the last character
      }
    } else {
      newValue = input.value.replace(/[^\d]/g, '');
    }

    // Ensure only 10 digits allowed
    // if (newValue.length > 10) {
    //   newValue = newValue.slice(0, 10);
    // }

    if (input.value !== newValue) {
      input.value = newValue; // Update input value only if it has changed
      input.dispatchEvent(new Event('input')); // Dispatch input event to notify Angular of the change
    }
  }
}
